<div
  [ngStyle]="{
    backgroundColor: 'rgb(var(' + bgColor + '))'
  }"
>
  <ng-content></ng-content>
  <div 
    *ngIf="v2Filter"
    class="layout-waves"
    [ngClass]="'layout-waves' + bgColor"
    [innerHtml]="wavesSvg | safeHtmlContent"
  ></div>
</div>
