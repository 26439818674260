<div class="form-group position-relative" [ngClass]="{'scroll': gallery.length > 2}">
  
  <div *ngIf="confirmShown" class="confirm-msg">
    <span class="confirm-msg__msg">¿Estas seguro de eliminar este archivo?</span>
    <div class="confirm-msg__buttons">
      <button 
        (click)="deleteFile(fileToBeDeleted)" 
        class="button button--danger button--small"
      >Confirmar</button>
      <button 
        (click)="confirmShown = false" 
        class="button button--primary button--small"
      >Cancelar</button>
    </div>
  </div>

  <div class="text-center p-2 row">
    <div class="col-sm-12">
      <div
        ng2FileDrop
        [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        class="well my-drop-zone"
      >
        <div class="mb-4" *ngIf="!hideInput && !config.onlyView">
          <span class="d-flex align-items-center gap-2 justify-content-center">
            <i class="icon-info text-color--primary body1"> </i>
            <span>Arrastra o selecciona 
            {{
              type === 'medias' 
              ? 'una imagen cuadrada para una mejor visualización' 
              : 'un archivo'
            }}</span>
          </span>
          <div class="text-center fileUpload py-5">
            <button
              type="button"
              class="button--primary button button--small"
              (click)="uploadFile.click()"
            >
             <i class="fa fa-regular fa-cloud-arrow-up"></i>
              Desde tu PC
            </button>
            <button
              *ngIf="showRepository"
              (click)="showFileCDNModal()"
              type="button"
              class="button--primary button button--small"
            >
              <i class="fa fa-regular fa-files"></i>
              Repositorio
            </button>
            <input
              class="upload"
              type="file"
              ng2FileSelect
              [accept]="fileInfoTypes"
              [uploader]="uploader"
              (change)="upload($event)"
              #uploadFile
            />
          </div>
          <small
            >(Límite de {{type === 'medias' ? 'imágenes' : 'archivos'}}
            {{uploader.options.queueLimit}})</small
          ><br />
          <small *ngIf="info && !csv && !xls"
            >(Tipos de {{type === 'medias' ? 'imágenes' : 'archivos'}}
            admitídos: {{
              respositoryExtensions.length ? fileInfoTypes : 
            type === 'medias' 
            ? '.jpeg, .png, .gif' 
            : fileInfoTypes}})</small
          >
          <small *ngIf="info && xls">(Tipo de archivo admitído: .xls, xlsx)</small>
          <small *ngIf="info && csv">(Tipo de archivo admitído: .csv)</small>
          <p
            *ngIf="fileError"
            class="label-error"
            margin-top
            style="color: red"
          >
            {{fileError}}
          </p>
        </div>

        <div class="gallery text-center d-flex justify-content-center" *ngIf="gallery.length">
          <div class="gallery--item" *ngFor="let file of gallery">
            <div class="gallery__img-wrapper">
              <div
                class="overlay"
                (click)="deleteFile(file)"
                tappable
                *ngIf="!config.onlyView"
              >
                <div class="close">X</div>
              </div>
              <img
                class="gallery__img"
                onerror="if (this.src !== 'assets/images/neuromindset/files/file-colored.png') this.src = 'assets/images/neuromindset/files/file-colored.png';"
                [src]="file?.url || file?.thumbnails?.original?.url || './assets/images/neuromindset/icons/message.png'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
