import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { headerAsideEnum } from '@interfaces/header-aside/header-aside-model.interface';
import { Programs } from '@interfaces/programs/programs.model.interface';
import { UserRolesEnum } from '@interfaces/user/user.enum';
import {
  BasicObservable,
  BasicObservableService,
} from '@services/basic-observable/basic-observable.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import {
  ProgramsMenuService,
  ProgramsService,
} from '@services/programs/programs.service';
import { StudentsImportService } from '@services/students/students.service';
import { UploadFilesComponent } from '@shared/components/upload-files/upload-files.component';
import {
  ASIDE_NAVIGATION_SECTION,
  HEADER_NAVIGATION_SECTION,
  MENU_SECTION,
  PROGRAM_SECTION,
} from '@shared/sections/sections';
import { ToastrService } from 'ngx-toastr';
import { filter, map, Subscription } from 'rxjs';

export const STUDENTS_BULK_SECTION = 'hasImports';
export const ERROR_STUDENTS_BULK_SECTION =
  'errors.students.importStudents.status';
export const IMPORT_STUDENT_START_SECTION = 'importStudents.start';

enum StudientError {
  BAD_DATE = 'Fecha invalida',
  BAD_NAME = 'Nombre o apellido invalido.',
  BAD_EMAIL = 'Email invalido.',
  ALREADY_EXISTS = 'Este estudiante ya estaba creado.',
  GENERIC_ERROR = 'Fila invalida',
}

enum ErrorMessages {
  CANT_PARSE = 'El servidor no puede procesar este documento. Contiene valores con formato o un error en la estructura del archivo.',
  EXCEEDED = 'Ha ocurrido un error en la carga. El número de alumnos es superior al permitido para esta línea. Inténtelo de nuevo, por favor.',
  BAD_MODEL = 'Error en la subida. El archivo no se corresponde con el modelo.',
  GENERIC = 'Ha ocurrido un error en la carga.',
}

interface ImportResult {
  errors: ImportError[];
  total: number;
  uploaded: number;
}

interface ImportError {
  value: string;
  message: StudientError;
}

@Component({
  selector: 'app-bulk',
  templateUrl: './bulk.component.html',
  styleUrls: ['./bulk.component.scss'],
})
export class BulkComponent implements OnInit, OnDestroy {
  @ViewChild('importFile', { static: false })
  importFile: UploadFilesComponent;
  //Subscriptions
  private subParams: Subscription;
  private subMenu: Subscription;
  private subProgram: Subscription;
  private subImport: Subscription;
  private subImportErrors: Subscription;
  private subImportStatus: Subscription;

  //Data models
  private basicNavigation: BasicObservable;
  public program: Programs;

  //Enums
  public UserRolesEnum = UserRolesEnum;

  //Booleans
  public isLoading: boolean;
  public isImporting: boolean;
  public startImport: boolean;

  //Variables
  public labHash: string;
  public url: string;

  public importResult: ImportResult;

  constructor(
    private basicObservableService: BasicObservableService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private programsService: ProgramsService,
    private menuService: ProgramsMenuService,
    private toastr: ToastrService,
    private importService: StudentsImportService,
    private errorsService: ErrorsService,
    protected storage: LocalStorageService,
  ) {}

  ngOnInit() {
    this.isImporting = false;
    this.isLoading = true;
    this.subParams = this.activatedRoute.params.subscribe((params) => {
      this.labHash = params.id;
      this.importService.cleanSection(STUDENTS_BULK_SECTION);
      this.errorsService.cleanSection(ERROR_STUDENTS_BULK_SECTION);
      this.getProgress();
    });

    this.updateNavigationMenu();

    this.subMenu = this.menuService
      .getObservable(MENU_SECTION)
      .subscribe((data) => this.updateSideNavigationMenu(data));

    this.subProgram = this.programsService
      .getObservable(PROGRAM_SECTION)
      .pipe(
        filter((programs) => !!programs?.length),
        map((programs) => programs[programs?.length - 1]),
      )
      .subscribe((program) => this.onProgram(program));

    this.subImportStatus = this.basicObservableService
      .getObservable(IMPORT_STUDENT_START_SECTION)
      .pipe(
        filter((imports) => !!imports),
        map((imports) => imports[imports?.length - 1]),
      )
      .subscribe((response) => this.checkStudentsImport(response));

    this.subImportErrors = this.errorsService
      .getObservable(ERROR_STUDENTS_BULK_SECTION)
      .subscribe((response) => this.onError(response));
  }

  //Menu setting
  private updateNavigationMenu() {
    this.basicNavigation = {
      hash: HEADER_NAVIGATION_SECTION,
      payload: {
        type: UserRolesEnum.TEACHER,
        fixed: true,
        background: true,
        home: false,
        progressBar: true,
        showLevel: false,
      },
    };
    this.basicObservableService.update(
      this.basicNavigation,
      HEADER_NAVIGATION_SECTION,
    );
  }
  private updateSideNavigationMenu(data) {
    this.basicNavigation = {
      hash: ASIDE_NAVIGATION_SECTION,
      payload: {
        show: true,
        showPartial: false,
        menuData: data,
        active: headerAsideEnum.students,
      },
    };
    this.basicObservableService.update(
      this.basicNavigation,
      ASIDE_NAVIGATION_SECTION,
    );
  }

  //Data setting
  private onProgram(data) {
    if (!this.program) this.getMenu(data.id);
    this.program = data;
    this.importFile.uploader.options.url = `${environment.api}/students/import/?program=${this.program?.id}`;
    this.importFile.uploader.options.autoUpload = false;
  }

  //Import
  private checkStudentsImport(response) {
    if (this.isImporting && response?.hash === '') {
      this.isImporting = !this.isImporting;
      this.importFile.hideInput = false;
      this.importFile.gallery = [];
      this.startImport = false;
    }
  }

  private onError(errors: ErrorServiceInterface[]) {
    if (!errors || errors?.length < 1) return;
    const error = errors[errors.length - 1].payload.error;
    const id = errors[errors.length - 1].id;
    this.errorsService.remove(id);
    this.isLoading = false;
    this.isImporting = false;
    this.startImport = false;
    this.importFile.hideInput = false;
    this.importFile.gallery = [];
    this.errorsService.cleanSection(ERROR_STUDENTS_BULK_SECTION);
  }

  public parseErrors(errors: string[]): ImportError[] {
    const errorRegex = /\((.*?)\),\s*(.*)/;

    const parseMessage = (msg: string): StudientError => {
      const errorMessages = {
        'bad date': StudientError.BAD_DATE,
        'bad format of email': StudientError.BAD_EMAIL,
        'wrong name or surname': StudientError.BAD_NAME,
        'student already created': StudientError.ALREADY_EXISTS,
      };

      const message = Object.entries(errorMessages).find(([message]) =>
        msg.toLocaleLowerCase().includes(message),
      );

      return message ? message[1] : StudientError.GENERIC_ERROR;
    };

    return errors.map((error) => {
      const [, value, message] = error.match(errorRegex);
      return {
        value,
        message: parseMessage(message),
      };
    });
  }

  public import() {
    this.isImporting = true;
    this.importFile.uploader.uploadItem(this.importFile.uploader.queue[0]);
    this.importFile.uploader.onSuccessItem = (
      item,
      response,
      status,
      headers,
    ) => {
      const data = JSON.parse(response)?.data;
      this.basicObservableService.update(
        { hash: 'students-imports', payload: data },
        IMPORT_STUDENT_START_SECTION,
      );
      this.importFile.delete(this.importFile.gallery[0]);
      this.isImporting = false;
      this.toastr.success('Alumnos importados correctamente!');
      this.importFile.uploader.clearQueue();
      this.importResult = {
        uploaded: data.uploaded,
        total: data.total,
        errors: this.parseErrors(data.errors),
      };
    };
    this.importFile.uploader.onErrorItem = (
      item,
      response,
      status,
      headers,
    ) => {
      const error = JSON.parse(response);

      this.isLoading = false;
      this.isImporting = false;

      const errorType =
        (error?.errors?.length && error?.errors[0]?.values[0]) ?? '';
      const exceeded = errorType.includes('exceeded');
      const columns = errorType.includes('headers');

      const MessageHandler = {
        500: ErrorMessages.CANT_PARSE,
        409: exceeded
          ? ErrorMessages.EXCEEDED
          : columns
          ? ErrorMessages.BAD_MODEL
          : ErrorMessages.GENERIC,
      };

      this.toastr.error(MessageHandler[status] || ErrorMessages.GENERIC);
      this.importFile.uploader.clearQueue();
      this.importFile.delete(this.importFile.gallery[0]);

      /*  
      switch (status) {
        case 500:
          this.toastr.error(ErrorMessages.CANT_PARSE);
          break;
        case 409:
            if (error.errors[0].values[0].includes('exceeded')) {
              this.toastr.error(
                'Error en la subida. Excedido el número de alumnos para esta línea',
              );
              this.importFile.fileError =
                'Ha ocurrido un error en la carga. El número de alumnos es superior al permitido para esta línea. Inténtelo de nuevo, por favor.';
            }
            if (error.errors[0].values[0].includes('headers')) {
              this.toastr.error(
                'Error en la subida. El archivo no se corresponde con el modelo.',
              );
              this.importFile.fileError =
                'Ha ocurrido un error en la carga. El archivo no se corresponde con el modelo.';
            }
            this.importFile.delete(this.importFile.gallery[0]);
            break;
          } 
          */
    };
  }

  //Gets
  private getProgress() {
    this.programsService.cleanSection(PROGRAM_SECTION);
    this.programsService.getAll({ laboratory: this.labHash }, PROGRAM_SECTION);
  }
  private getMenu(line: string) {
    this.menuService.cleanSection(MENU_SECTION);
    this.menuService.getAll(
      { program: line, laboratory: this.labHash },
      MENU_SECTION,
    );
  }

  //navigate
  public navigateReturn() {
    this.router.navigate([`admin/lab/${this.labHash}/students`]);
  }

  ngOnDestroy() {
    this.subMenu.unsubscribe();
    this.subParams.unsubscribe();
    this.subProgram.unsubscribe();
  }
}
