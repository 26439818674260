import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import {
  BasicObservable,
  BasicObservableService,
} from '@services/basic-observable/basic-observable.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IMPORT_STUDENT_START_SECTION } from './modules/admin/modules/lab/components/students/components/bulk/bulk.component';
import { IMPORT_TEACHERS_START_SECTION } from './modules/admin/modules/teachers/components/bulk/bulk.component';

const ignoredErrorSections = [
  'errors.categories',
  'errors.processes',
  'errors.subprocesses',
  'errors.activities-types',
  'errors.activities-categorized',
  'errors.themes',
  'errors.forgotPassword',
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subErrors: Subscription;
  private subMetas: Subscription;

  constructor(
    private router: Router,
    private errorsService: ErrorsService,
    private toastr: ToastrService,
    private pageScrollService: PageScrollService,
    private basicObservableService: BasicObservableService,
    private metaTagService: Meta,
    private titleService: Title,
    protected readonly storage: LocalStorageService,
    protected readonly authService: AuthService,
    @Inject(DOCUMENT) private document: any,
  ) {}

  ngOnInit() {
    const basicMeta: BasicObservable = {
      hash: 'seo-metas',
      payload: {
        title: 'Neuromindset',
        metas: [
          { name: 'keywords', content: 'Neuromindset, Prueba SEO' },
          { name: 'robots', content: 'index, follow' },
          { name: 'author', content: 'Kubide' },
          { name: 'description', content: 'Neuromindset Home' },
          { name: 'date', content: '2020-02-07', scheme: 'YYYY-MM-DD' },
        ],
      },
    };

    const hashImports: BasicObservable = {
      hash: 'students-imports',
      payload: {
        hash: '',
      },
    };

    const hashImportsTeacher: BasicObservable = {
      hash: 'teachers-imports',
      payload: {
        hash: '',
      },
    };

    this.basicObservableService.create(basicMeta, 'seo-metas');
    this.basicObservableService.create(
      hashImports,
      IMPORT_STUDENT_START_SECTION,
    );
    this.basicObservableService.create(
      hashImportsTeacher,
      IMPORT_TEACHERS_START_SECTION,
    );

    this.storage.load('localUser');

    this.authService.loadTokens();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.pageScrollService.scroll({
          document: this.document,
          scrollTarget: 'body',
          scrollOffset: 0,
          duration: 0,
        });
      });

    this.subMetas = this.basicObservableService
      .getObservable('seo-metas')
      .pipe(
        filter((metas: BasicObservable[]) => !!metas),
        map((metas: BasicObservable[]) => metas[metas.length - 1]),
      )
      .subscribe((metas: BasicObservable) => this.setMetas(metas.payload));

    this.subErrors = this.errorsService
      .getObservable('errors')
      .pipe(
        filter((errors) => !!errors && !!errors.length),
        map((errors) => errors[errors.length - 1]),
      )
      .subscribe((error) => {
        this.onError(error);
      });
  }

  private hasIgnoredSection(section: string): boolean {
    return ignoredErrorSections.some((ignoredSection) =>
      section.includes(ignoredSection),
    );
  }

  onError(error: ErrorServiceInterface) {
    if (!error || this.hasIgnoredSection(error.section)) return;

    const errorInfo = error?.payload?.error;
    const statusCode = errorInfo?.statusCode || '';

    if (!errorInfo) {
      console.log('Unhandled error', error);
      this.errorsService.remove(error?.id);
      this.errorsService.cleanSection(error?.section);
      return;
    }

    const message: string = errorInfo?.errors[0]?.values[0] || '';

    switch (statusCode) {
      case 404:
        if (errorInfo?.errors[0]?.values?.indexOf('schoolInvitations') > -1) {
          this.toastr.error('Algo ha ido mal. Contacte con el colegio');
          return;
        }
        this.router.navigate(['/error', '404']);
        return;
      case 401:
        if (message?.includes('wrong_credentials')) {
          this.toastr.error('Los datos introducidos no son correctos');
          return;
        }
        if (message?.includes('audience')) {
          this.toastr.error('Algo ha ido mal. Vuelva a intentarlo otra vez.');
          return;
        }
      case 403:
        if (message?.includes('audience')) {
          this.toastr.error('No está autorizado. Vuelva a intentarlo');
          return;
        }
      case 409:
        if (message?.includes('email'))
          this.toastr.error('Ya existe un usuario con ese email');
        return;
    }

    this.toastr.error(error?.payload?.message);
    this.errorsService.remove(error?.id);
    this.errorsService.cleanSection(error?.section);
  }

  protected setMetas(metas) {
    metas.metas.forEach((meta) => {
      this.metaTagService.updateTag({ name: meta.name, content: meta.content });
    });
    this.titleService.setTitle(metas.title);
  }

  ngOnDestroy() {
    this.subErrors.unsubscribe();
  }
}
