import { LaddaConfigArgs } from 'angular2-ladda';
// import { IPartialConfigOptions } from 'ng2-ui-auth/lib/config-interfaces';

export const environment = {
  production: true,
  static: '',
  api: 'https://api.pilot.schools.neuromindset.com/v1',
  client: {
    name: 'sbft9mzOi',
    secret: 'N99Nj2nFphmjfFtL',
  },
  sentry: {
    url: '',
    active: '',
  },
  ladda: {
    style: 'zoom-in',
    spinnerSize: 40,
    spinnerColor: 'white',
    spinnerLines: 12,
  } as LaddaConfigArgs,
  toaster: {
    timeOut: 8000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
  },
  storageDB: {
    name: 'neuromindset',
  },
  sessionValorationValue: 50,
  tpvUrl: 'https://hpp.sandbox.addonpayments.com/pay',
  pricing: {
    standard: '575',
    premium: '900',
  },
  // socialAuth: {} as IPartialConfigOptions,
};

// environment.socialAuth = {
//   baseUrl: environment.api,
//   providers: {
//     linkedin: {
//       url: 'tokens',
//       clientId: '86az306lssh0h3',
//     },
//     facebook: {
//       url: 'tokens',
//       clientId: '1999260230336024',
//     },
//     google: {
//       url: 'tokens',
//       clientId: '392164456106-3jtkjfjc6hv92o1ccjqd053ktgu5ksls',
//     },
//   },
// };
