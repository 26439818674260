import { CommonModule } from '@angular/common';
import { Injectable, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { environment } from '@environments/environment';
import { ActivitiesCategorizedServiceModule } from '@services/activities-categorized/activities-categorized.module';
import { ActivityTypesServiceModule } from '@services/activity-types/activity-types.module';
import { AuthModule } from '@services/auth/auth.module';
import { BasicObservableModule } from '@services/basic-observable/basic-observable.module';
import { CategoriesServiceModule } from '@services/categories/categories.module';
import { ErrorsModule } from '@services/errors/errors.module';
import { FileInventoryModule } from '@services/file-inventory/file-inventory.module';
import { LoadContentModule } from '@services/load-content/load-content.module';
import { LocalStorageModule } from '@services/local-storage/local-storage.module';
import { ProcessesServiceModule } from '@services/processes/processes.module';
import { SchoolsServiceModule } from '@services/schools/schools.module';
import { SessionStorageModule } from '@services/session-storage/session-storage.module';
import { SubprocessesServiceModule } from '@services/subprocesses/subprocesses.module';
import { TeachersServiceModule } from '@services/teachers/teachers.module';
import { ThemesServiceModule } from '@services/themes/themes.module';
import { UsersServiceModule } from '@services/users/users.module';
import { BaseLayoutModule } from '@shared/components/base-layout/base-layout.module';
import { LaddaModule } from 'angular2-ladda';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { FooterComponent } from './components/footer/footer.component';
import { PercentageBarModule } from './modules/admin/components/percentage-bar/percentage-bar.module';
import * as Sentry from '@sentry/browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

Sentry.init({ dsn: environment.sentry.url });

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const parsedError = error.originalError || error;

    if (environment.production) {
      const eventId = Sentry.captureException(error.originalError || error);
      Sentry.showReportDialog({ eventId });
    } else {
      console.error(parsedError);
    }
  }
}

@NgModule({
  declarations: [AppComponent, FooterComponent],
  imports: [
    BaseLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    NgxPageScrollCoreModule,
    ToastrModule.forRoot(environment.toaster),
    LaddaModule.forRoot(environment.ladda),
    ErrorsModule.forRoot(),
    UsersServiceModule.forRoot(),
    TeachersServiceModule.forRoot(),
    SchoolsServiceModule.forRoot(),
    BasicObservableModule.forRoot(),
    SessionStorageModule.forRoot(),
    LocalStorageModule.forRoot(),
    AuthModule.forRoot(),
    RouterModule.forRoot(routes),
    PercentageBarModule,
    LoadContentModule.forRoot(),
    FileInventoryModule.forRoot(),
    CategoriesServiceModule.forRoot(),
    ProcessesServiceModule.forRoot(),
    ActivityTypesServiceModule.forRoot(),
    SubprocessesServiceModule.forRoot(),
    ActivitiesCategorizedServiceModule.forRoot(),
    ThemesServiceModule.forRoot(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
