import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { environment } from '@environments/environment';
import { LocalStorageModule } from '@services/local-storage/local-storage.module';
// import { Ng2UiAuthModule } from 'ng2-ui-auth';
// import { IPartialConfigOptions } from 'ng2-ui-auth/lib/config-interfaces';
import { SessionStorageModule } from '../session-storage/session-storage.module';
import { AuthGuard } from './auth.guard';
import { AuthHttpInterceptor } from './auth.http.interceptor';
import { AuthService } from './auth.service';
import { AuthAdminGuard } from './auth-admin.guard';

// const MyAuthConfig: IPartialConfigOptions = {
//   baseUrl: environment.api,
//   providers: {
//     linkedin: {
//       url: 'tokens',
//       clientId: '86az306lssh0h3',
//     },
//     facebook: {
//       url: 'tokens',
//       clientId: '1999260230336024',
//     },
//     google: {
//       url: 'tokens',
//       clientId: '392164456106-3jtkjfjc6hv92o1ccjqd053ktgu5ksls',
//     },
//   },
// };

@NgModule({
  imports: [
    CommonModule,
    LocalStorageModule,
    HttpClientModule,
    SessionStorageModule,
    // Ng2UiAuthModule.forRoot(MyAuthConfig),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthGuard, AuthService, AuthAdminGuard],
    };
  }
}
