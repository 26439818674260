import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ExtendedRecordset } from '@interfaces/global/extendedRecordset.interface';
import {
  Credentials as CredentialsInterface,
  Token as TokenInterface,
} from '@interfaces/token/token.interface';
import { UserInterface } from '@interfaces/user/user.interface';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export abstract class AbstractAuthService {
  public currentUser: UserInterface;
  public currentToken: string;
  public currentRefreshToken: string;
  public headers: string;
  public isRefreshing = false;

  protected dataStore: {
    tokens: TokenInterface;
    user: UserInterface;
    headers: HttpHeaders;
  };
  protected observables: {
    tokens: BehaviorSubject<TokenInterface>;
    user: BehaviorSubject<UserInterface>;
    headers: BehaviorSubject<HttpHeaders>;
  };
  protected behaviorSubjects: {
    tokens: Observable<TokenInterface>;
    user: Observable<UserInterface>;
    headers: Observable<HttpHeaders>;
  };

  protected baseUrl: string;
  protected errorsSection: string;
  protected clientID = environment.client.name;
  protected clientSecret = environment.client.secret;

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    protected storage: LocalStorageService,
    protected session: SessionStorageService,
  ) {
    this.errorsSection = 'errors.auth';
    this.headers = 'Headers';
    this.baseUrl = `${environment.api}/tokens`;

    this.dataStore = {
      tokens: {} as TokenInterface,
      user: {} as UserInterface,
      headers: {} as HttpHeaders,
    };
    this.observables = {
      tokens: new BehaviorSubject(null) as BehaviorSubject<TokenInterface>,
      user: new BehaviorSubject(null) as BehaviorSubject<UserInterface>,
      headers: new BehaviorSubject(null) as BehaviorSubject<HttpHeaders>,
    };

    this.behaviorSubjects = {
      tokens: this.observables.tokens.asObservable(),
      user: this.observables.user.asObservable(),
      headers: this.observables.headers.asObservable(),
    };
  }

  getObservable(section = 'user'): Observable<any> {
    return this.behaviorSubjects[section];
  }

  // login
  public login(credentials: CredentialsInterface, isLocalStorage = false) {
    const clientCredentials = btoa(`${this.clientID}:${this.clientSecret}`);

    Object.assign(credentials, {
      grant_type: 'password',
    })
    
    const headers = new HttpHeaders().set(
      'Authorization',
      `Basic ${clientCredentials}`,
    );

    this.http
      .post(this.baseUrl, credentials, { observe: 'response', headers })
      .subscribe(
        (resp) => {
          this.loginSuccess(resp.body, resp.headers, isLocalStorage);
        },
        (error) => {
          this.loginError(error);
        },
      );
  }

  protected loginSuccess(
    body,
    headers: HttpHeaders = {} as HttpHeaders,
    isLocalStorage = false,
  ) {
    body = body.data;
    body.expires_in = this.parseExpiresIn(
      body.access_token,
      body.expires_in / 2,
    );
    this.setDataStore(body as TokenInterface, headers, isLocalStorage);
  }

  protected loginError(error) {
    this.logout();
    const section =
      error.status === 401 || error.message === 'Authentication Canceled'
        ? this.errorsSection
        : 'standard';
    this.errorsService.create(section, {
      payload: error,
    } as ErrorServiceInterface);
  }

  public logout() {
    this.setDataStore();
  }

  // refresh
  refresh(isLocalStorage: boolean = false, token?: string) {
    const refreshToken = token ? token : this.dataStore.tokens.refresh_token;
    const clientCredentials = btoa(`${this.clientID}:${this.clientSecret}`);
    const credentials = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    } as CredentialsInterface;
    const headers = new HttpHeaders().set(
      'Authorization',
      `Basic ${clientCredentials}`,
    );

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.http
        .post(this.baseUrl, credentials, { observe: 'response', headers })
        .subscribe(
          (resp: HttpResponse<ExtendedRecordset<any>>) => {
            const body = resp.body.data;
            body.expires_in = this.parseExpiresIn(
              body.access_token,
              body.expires_in / 2,
            );
            this.setDataStore(
              body as TokenInterface,
              resp.headers,
              isLocalStorage,
            );
            this.isRefreshing = false;
          },
          (error) => {
            this.logout();
            const section =
              error.status === 401 ? this.errorsSection : 'standard';
            this.errorsService.create(section, {
              payload: error,
            } as ErrorServiceInterface);
          },
        );
    }
  }

  // loadTokenFromStorageOrSession
  loadTokens() {
    let storageSub: Subscription;
    let sessionStorageSub: Subscription;

    storageSub = this.storage
      .getObservable('loadToken')
      .pipe(
        filter((store: any) => !!store),
        map((store: any) => store.tokens),
      )
      .subscribe((tokens) => {
        if (storageSub) {
          storageSub.unsubscribe();
        }

        if (!tokens) {
          this.session.load('tokens', 'loadToken');
        } else {
          this.setToken(tokens, true);
        }
      });

    sessionStorageSub = this.session
      .getObservable('loadToken')
      .pipe(
        filter((sessionStore) => typeof sessionStore.tokens !== 'undefined'),
        map((sessionStore) => sessionStore.tokens),
      )
      .subscribe((tokens) => {
        if (sessionStorageSub) {
          sessionStorageSub.unsubscribe();
        }

        if (tokens) {
          this.setToken(tokens, false);
        }
      });

    this.storage.load('tokens', 'loadToken');
  }

  protected setToken(tokens, isLocalStorage) {
    this.setDataStore(tokens, {} as HttpHeaders, isLocalStorage);
    const unixDate = Math.floor(Date.now() / 1000);

    if (tokens.expires_in <= unixDate) {
      this.refresh(true);
    }
  }

  protected setDataStore(
    tokens: TokenInterface = {} as TokenInterface,
    headers: HttpHeaders = {} as HttpHeaders,
    isLocalStorage: boolean = false,
  ) {
    if (tokens.access_token && tokens.user) {
      if (isLocalStorage) {
        this.storage.create({ key: 'tokens', value: tokens });
        this.storage.create({ key: 'user', value: tokens.user });
      } else {
        this.session.create({ key: 'tokens', value: tokens });
        this.session.create({ key: 'user', value: tokens.user });
      }
    } else {
      this.storage.remove('tokens');
      this.storage.remove('user');
      this.storage.remove('localUser');
      this.session.remove('tokens');
      this.session.remove('user');
    }

    this.dataStore.tokens = tokens;
    this.observables.tokens.next(Object.assign({}, this.dataStore).tokens);
    this.currentToken = tokens.access_token;
    this.currentRefreshToken = tokens.refresh_token;

    const user = tokens.user ? tokens.user : undefined;
    this.dataStore.user = user;
    this.currentUser = user;

    this.observables.user.next(Object.assign({}, this.dataStore).user);
    this.dataStore.headers = headers;
    this.observables.headers.next(Object.assign({}, this.dataStore).headers);
  }

  protected parseExpiresIn(accessToken, reduceTime = 0) {
    let tokens = accessToken.split('.');
    tokens = JSON.parse(atob(tokens[1]));
    return tokens.exp - reduceTime;
  }

  public abstract loginLinkedIn(): Promise<void>;

  public abstract loginFacebook(): Promise<void>;

  public abstract loginGoogle(): Promise<void>;
}
